import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { RichText } from 'gatsby-theme-q3/src/components';
import * as Accessa from 'accessa';
import SEOHelmet from '../components/SEOHelmet';

const Services = ({
  data: {
    service: { body, title, image },
  },
}) => (
  <Box style={{ backgroundColor: '#FFF' }}>
    <SEOHelmet title={title} />
    <Accessa.Layouts.Neapolitan
      focalComponent={
        <Box
          p={3}
          minHeight="350px"
          height="100%"
          width="100%"
        >
          <Accessa.Partials.Blanket
            contain
            {...image}
            style={{
              mixBlendMode: 'multiply',
            }}
          />
        </Box>
      }
    >
      <Box p={4}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} color="inherit" to="/">
            Home
          </MuiLink>
          <MuiLink
            component={Link}
            color="inherit"
            to="/services"
          >
            Services
          </MuiLink>
          <Typography color="textPrimary">
            {title}
          </Typography>
        </Breadcrumbs>
        <RichText json={JSON.parse(body?.raw)} />
      </Box>
    </Accessa.Layouts.Neapolitan>
  </Box>
);

Services.propTypes = {
  data: PropTypes.shape({
    service: PropTypes.shape({
      // eslint-disable-next-line
      body: PropTypes.object,
      title: PropTypes.string,
      // eslint-disable-next-line
      image: PropTypes.object,
    }),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        to: PropTypes.string,
        // eslint-disable-next-line
        image: PropTypes.object,
      }),
    ).isRequired,
  }).isRequired,
};

export default Services;

export const query = graphql`
  query getServiceById($contentful_id: String) {
    service: contentfulService(
      contentful_id: { eq: $contentful_id }
    ) {
      title
      body {
        raw
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }

    services: allContentfulService(
      filter: { contentful_id: { ne: $contentful_id } }
    ) {
      nodes {
        title
        to
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
